// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import { useState } from "react";

function Newsletter() {
  const [email, setEmail] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [error, setError] = useState(false);
  // const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick = async () => {
    if (email !== "" && email.includes(`@`)) {
      // setIsSubmitting(true);
      try {
        const data = { email: email };
        const response = await fetch("https://api.chefaicookware.com/homepage/newsletter", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        const result = await response.json();
        // setIsSubmitting(false);
        setHasSubmitted(true);
        console.log("Successfully subscribed: " + result);
      } catch (error) {
        console.log(error);
        // setIsSubmitting(false);
        setError({
          error: true,
          message: error.message,
        });
        console.log("There was an error...");
      }
    }
  };

  const handleTextInputChange = (event) => {
    setEmail(event.target.value);
  };

  return !hasSubmitted && !error ? (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5} mr="auto">
            <MKTypography variant="h4" mb={1}>
              Subscribe to our Newsletter
            </MKTypography>
            <MKTypography variant="body2" color="text">
              Get news about our latest development and features in your inbox every two weeks!
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} flexDirection="column" justifyContent="center" ml="auto">
            <MKBox component="form" method="" action="">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <MKInput label="Your Email..." fullWidth onChange={handleTextInputChange} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MKButton
                    variant="gradient"
                    color="gold"
                    fullWidth
                    sx={{ height: "100%" }}
                    onClick={handleClick}
                  >
                    Subscribe
                  </MKButton>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  ) : (
    <MKBox display="flex" alignItems="center" justifyContent="center" mt={8} mb={12}>
      <h1>Thank you for subscribing!</h1>
    </MKBox>
  );
}

export default Newsletter;
