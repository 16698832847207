// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
import Counters from "pages/presentation/sections/Counters";
import Newsletter from "pages/presentation/sections/Newsletter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Animation
import Animation from "./animation";

function Presentation() {
  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <Animation />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <MKBox display="flex" alignItems="center" justifyContent="center" mt={20} mb={12}>
          <iframe
            width="600px"
            height="400px"
            src="https://www.youtube.com/embed/oKfMG9T19VA?autoplay=1&loop=1&playlist=oKfMG9T19VA"
            title="CHEF AI Demo Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </MKBox>
        <Newsletter />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
