import React, { useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";

const logoSrc = "/chefai-transparent.svg";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #000;
  background-image: radial-gradient(
      circle at 10% 30%,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.8) 50%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(circle at 90% 70%, rgba(218, 165, 32, 0.05) 0%, rgba(0, 0, 0, 0) 70%);
  overflow: hidden;
  perspective: 1000px;
`;

const Logo = styled.img`
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: auto;
  z-index: 10;
`;

const TextContainer = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 15;
`;

const StartupName = styled.h1`
  color: #faecd3;
  font-size: 3rem;
  margin-bottom: 2rem;
  margin-top: 5rem;
  opacity: 0;
  animation: ${fadeIn} 2s ease-in-out forwards;
`;

const Tagline = styled.h2`
  color: #faecd3;
  font-size: 1.5rem;
  font-weight: 300;
  opacity: 0;
  animation: ${fadeIn} 2s ease-in-out 0.5s forwards;
  text-align: center;
`;

const Line = styled.div`
  white-space: nowrap; /* Ensure each line does not wrap */
  margin-bottom: 1rem;
`;

const GridContainer = styled.div`
  position: absolute;
  top: 27%;
  left: 0;
  right: 0;
  bottom: 0;
  transform: rotateX(60deg) rotateZ(0deg);
  transform-style: preserve-3d;
`;

const GridCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ParticleCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

const LogoParticles = () => {
  const gridCanvasRef = useRef(null);
  const particleCanvasRef = useRef(null);
  const particlesRef = useRef([]);
  const logoRef = useRef({ x: 0, y: 0, width: 0, height: 0 });

  useEffect(() => {
    const gridCanvas = gridCanvasRef.current;
    const particleCanvas = particleCanvasRef.current;
    const gridCtx = gridCanvas.getContext("2d");
    const particleCtx = particleCanvas.getContext("2d");
    let gridAnimationFrameId;
    let particleAnimationFrameId;

    const resizeCanvas = () => {
      gridCanvas.width = window.innerWidth;
      gridCanvas.height = window.innerHeight;
      particleCanvas.width = window.innerWidth;
      particleCanvas.height = window.innerHeight;

      const logoElement = document.querySelector("img");
      if (logoElement) {
        const rect = logoElement.getBoundingClientRect();
        logoRef.current = {
          x: rect.left + rect.width * 0.2,
          y: rect.top + rect.height / 2,
          width: rect.width,
          height: rect.height,
        };
      }
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    const createParticle = () => {
      const startX = -window.innerWidth * 0.25;
      const startY = window.innerHeight * 0.25 * Math.random();
      const startZ = Math.random() * 500 + 200;
      const baseSpeed = 0.3;
      const speedVariance = 0.2;
      return {
        x: startX,
        y: startY,
        z: startZ,
        size: Math.random() * 2 + 1,
        speed: baseSpeed + Math.random() * speedVariance,
        progress: 0,
      };
    };

    const updateParticles = () => {
      particlesRef.current.forEach((particle, index) => {
        particle.progress += particle.speed / 150;

        if (particle.progress >= 1) {
          particlesRef.current.splice(index, 1);
        }
      });

      while (particlesRef.current.length < 300) {
        particlesRef.current.push(createParticle());
      }
    };

    const drawGrid = (time) => {
      gridCtx.clearRect(0, 0, gridCanvas.width, gridCanvas.height);

      gridCtx.strokeStyle = "rgba(255, 232, 184, 0.1)";
      gridCtx.lineWidth = 3;
      const gridSize = 100;
      const rows = Math.ceil(gridCanvas.height / gridSize);
      const cols = Math.ceil(gridCanvas.width / gridSize);

      for (let i = 0; i <= rows; i++) {
        gridCtx.beginPath();
        for (let j = 0; j <= cols; j++) {
          const x = j * gridSize;
          const frequency = 0.005;
          const amplitude = 100;
          const y = i * gridSize + Math.sin((x + time) * frequency) * amplitude;
          if (j === 0) {
            gridCtx.moveTo(x, y);
          } else {
            gridCtx.lineTo(x, y);
          }
        }
        gridCtx.stroke();
      }

      for (let j = 0; j <= cols; j++) {
        gridCtx.beginPath();
        for (let i = 0; i <= rows; i++) {
          const x = j * gridSize + Math.sin((i * gridSize + time) * 0.01) * 5;
          const y = i * gridSize;
          if (i === 0) {
            gridCtx.moveTo(x, y);
          } else {
            gridCtx.lineTo(x, y);
          }
        }
        gridCtx.stroke();
      }

      gridAnimationFrameId = requestAnimationFrame(() => drawGrid(time + 1));
    };

    const drawParticles = () => {
      particleCtx.clearRect(0, 0, particleCanvas.width, particleCanvas.height);

      particlesRef.current.forEach((particle) => {
        const t = particle.progress;

        const startX = particle.x;
        const startY = particle.y;
        const startZ = particle.z;

        const endX = window.innerWidth * 0.6;
        const endY = window.innerHeight / 2;

        const cp1x = startX + (endX - startX) * 0.3;
        const cp1y = startY + (endY - startY) * 0.3;

        const cp2x = startX + (endX - startX) * 0.7;
        const cp2y = endY;

        const x =
          Math.pow(1 - t, 3) * startX +
          3 * Math.pow(1 - t, 2) * t * cp1x +
          3 * (1 - t) * Math.pow(t, 2) * cp2x +
          Math.pow(t, 3) * endX;
        const y =
          Math.pow(1 - t, 3) * startY +
          3 * Math.pow(1 - t, 2) * t * cp1y +
          3 * (1 - t) * Math.pow(t, 2) * cp2y +
          Math.pow(t, 3) * endY;

        const finalX = x * (1 - t * (startZ / 1000));
        const finalY = y * (1 - t * (startZ / 1000));

        const opacity = Math.sin(t * Math.PI);

        particleCtx.beginPath();
        particleCtx.arc(finalX, finalY, particle.size * (1 - t * (startZ / 1000)), 0, Math.PI * 2);
        particleCtx.fillStyle = `rgba(255, 232, 184, ${opacity})`;
        particleCtx.fill();
      });
    };

    const animateParticles = () => {
      updateParticles();
      drawParticles();
      particleAnimationFrameId = requestAnimationFrame(animateParticles);
    };

    drawGrid(0);
    animateParticles();

    return () => {
      cancelAnimationFrame(gridAnimationFrameId);
      cancelAnimationFrame(particleAnimationFrameId);
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  return (
    <Container>
      <Logo src={logoSrc} alt="CHEF AI Logo" />
      <TextContainer>
        <StartupName>CHEF AI</StartupName>
        <Tagline>
          <Line>Effortless Meal Guidance, Fresh Groceries, and Zero-Touch Cooking</Line>
        </Tagline>
      </TextContainer>
      <GridContainer>
        <GridCanvas ref={gridCanvasRef} />
      </GridContainer>
      <ParticleCanvas ref={particleCanvasRef} />
    </Container>
  );
};

export default LogoParticles;
